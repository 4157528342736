"use client";
import type { Decision } from "api/ads/types";
import { memo, useEffect, useMemo, useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import { resolver as adsResolver } from "api/ads";
import { gtmEvent, gtmPurchaseEvent } from "utils/gtm";
import { useVisibility } from "../../hooks/use-visibility";
import { MinimalProduct } from "api-v2/products/types";
import { ProductCard } from "../molecules/product-card-refreshed";
import { useStore } from "@nanostores/react";
import { $ads } from "stores/ads";
import { cn } from "../../utils";

interface KevelReviewTileProps {
  className?: string;
}

const KevelReviewTile = memo(({ className }: KevelReviewTileProps) => {
  const ads = useStore($ads);
  const [ad, setAd] = useState<Decision | null>(null);

  const { ref, inView } = useInView({ threshold: 0.5 });
  const [impressionEventFired, setImpressionEventFired] = useState(false);
  const [viewEventFired, setViewEventFired] = useState(false);
  const tabActive = useVisibility();
  const viewTimer = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if ("reviewTile" in ads) {
      const currentAd = ads.reviewTile;
      if (currentAd) setAd(currentAd[0]);
    } else {
      setAd(null);
    }
  }, [ads]);

  const sendDeliveredEvent = async () => {
    if (!ad) return;

    gtmEvent(`kevel_native_ad_delivered`, ad);
    await adsResolver.sendImpression(ad.impressionUrl);

    console.log(ad.adId, "Sending delivered event");
  };

  const sendViewEvent = async () => {
    if (!ad) return;

    gtmEvent("kevel_display_ad_inview", ad);

    const viewEvent = ad.events.find(({ id }) => id === 40);
    if (!viewEvent) return;

    await adsResolver.sendImpression(viewEvent.url);
    console.log(ad.adId, "Sending view event");
  };

  useEffect(() => {
    if (!ad) return;

    if (!impressionEventFired) {
      void sendDeliveredEvent();
      setImpressionEventFired(true);
    }

    if (inView && tabActive) {
      if (!viewEventFired)
        viewTimer.current = setTimeout(() => {
          void sendViewEvent();
          setViewEventFired(true);
        }, 1000);
    } else {
      return clearTimeout(viewTimer.current);
    }

    return () => {
      return clearTimeout(viewTimer.current);
    };
  }, [ad, inView, tabActive, impressionEventFired, viewEventFired]);

  const currentAd = useMemo(() => {
    if (!ad) return;

    const data = ad.contents[0].data;
    const product: MinimalProduct | undefined = data.ctProduct;

    const handleClickout = () => {
      gtmEvent("kevel_native_ad_clicked", ad);
    };

    const lowestPrices = product?.lowestPrices || [];

    const clickUrl =
      data.ctShopId && lowestPrices[0]?.amount > 0
        ? `${ad.clickUrl}&url=${lowestPrices[0].link}`
        : ad.clickUrl;

    return (
      <div ref={ref} className={cn("hidden lg:block text-center", className)}>
        <h3 className="mb-1">Uitgelichte producten</h3>
        <ProductCard
          ref={ref}
          key={product?.kieskeurigId || ad.adId}
          kieskeurigId={product?.kieskeurigId || ad.adId}
          title={product?.title || data.ctTitle}
          thumbnail={product?.thumbnail || data.ctDisplayImageUrl}
          specificationSpotlight={product?.specificationSpotlight || {}}
          highlightSpecifications={product?.highlightSpecifications || []}
          reviewScore={product?.reviewScore || 0}
          reviewCount={product?.reviewCount || 0}
          certificates={product?.certificates || []}
          lowestPrices={product?.lowestPrices! || []}
          link={clickUrl}
          discount={product?.discount || 0}
          brand={product?.brand || ""}
          categorySlug={product?.categorySlug || "overig"}
          priceAmount={product?.priceAmount || 0}
          buttonText={data.ctClickoutLinkText}
          textAd={data.ctText}
          linkTarget={data.ctTarget}
          onClickout={handleClickout}
          additionalCpc={Number(data.ctCpcValue || 0)}
          minimal
          sponsored
          showTestpanelScore
        />
      </div>
    );
  }, [ad]);

  return currentAd;
});

KevelReviewTile.displayName = "KevelReviewTile";

export { KevelReviewTile };
